
.myWalletTop{
    margin: 2vh;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #999;
    padding: 1vh 1vh 5vh 1vh;
    color: #fff;
}
.myWalletBottom{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2vh;
    margin: 2vh;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #999;
}
.topTxt{
    font-size: 30px;
    font-weight: bold;
}