@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#root{
  width: 100%;
  min-height: 100%;
  height: auto !important;
  /* overflow: hidden; */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.modalTitle1{
  height: 44px;
  display: flex;
  align-items:center;
  margin-left:15px;
  color:#cc351e;
  font-weight:bold;
}
.modalStyle2{
  width:  80% !important;
  height: auto !important;
}
.modalStyle2 >.am-modal-content{
  border-radius: 10px;
}
.modalStyle2 .am-modal-header{
  background: #cc351e;
  padding: 12px;
}
.modalStyle2 .am-modal-title{
  color: #fff !important;
}
.modal2Body{
  font-size: 1em;
  margin: 6vh;
}
.modalStyle2Btn{
  display: flex;
  border-top: 1px solid #ddd;
}
.modalStyle2BtnDiv{
  width: 50%;
  padding: 10px;
}
.am-wingblank.am-wingblank-lg{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.pickerItem{
  display: flex;
  justify-content: space-around;
}
.ant-tabs-nav{
  height: 8vh !important;
}
.tabsTitle{
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vh;
  height: 6.6vh;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.wantRecommendBtn{
  border: 1px solid #cc351e;
  color: #cc351e;
  border-radius: 5px;
  padding: 0.4vh;
  font-size: 12px;
  font-weight: normal;
}
.flex{
  display: flex;
}
.popupModal{
  top:unset !important;
  bottom: 0 !important;
}
.am-list-view-scrollview-content{
  width: 100% !important;
}
.popup-list .am-list-body {
  height: 40vh;
  overflow: auto;
}
.topDiv{
  position: relative;
  background:#cc351e;
  height:8vh;
  display:flex;
  align-items:center;
  justify-content:center;
}
.seachDiv{
  width: 80%;
  text-align: center;
  background: #fff;
  padding: 1vh 4vh;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}
.isShowCarousel .ant-tabs-ink-bar{
  background: #cc351e;
}
.isShowCarousel .am-tabs-default-bar-tab-active{
  color: #cc351e !important;
}
.noShowCarousel .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
}
.noShowCarousel .am-tabs-default-bar{
  background: #cc351e !important;
}
.noShowCarousel .am-tabs-default-bar-tab-active,.noShowCarousel .am-tabs-default-bar-content{
  color: #fff;
}
.am-tabs-default-bar-top .am-tabs-default-bar-tab{
  font-weight: bold;
}
.ant-tabs-tab-btn, .ant-tabs-tab-remove{
  font-weight: bold !important;
}
.ant-tabs-tab+.ant-tabs-tab{
  margin: 0 0 0 141px !important;
}
.isShowCarousel .am-tabs-default-bar-underline{
  width: 20% !important;
  margin-left: 15% !important;
  border: 1px solid #cc351e !important;
}
.isShowCarousel .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 14px;
  font-weight: bold;
  color: #cc351e !important;
}
.noShowCarousel .am-tabs-default-bar-underline{
  /* width: 0px !important;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 10px solid rgb(255, 255, 255) !important;
  margin-left: 15px !important;
  background: none !important; */
  width: 0px !important;
  border-style: solid !important;
  border-width: 0px 10px 10px !important;
  border-color: transparent #cc351e !important;
  background: rgb(255, 255, 255) !important;
  margin-left: 23% !important;
    /* left: 88px; */
}
.hongbaoDiv{
  position: fixed;
  bottom: 1.5vh;
  right: 2vh;
}
.ant-tabs-top > .ant-tabs-nav{
  margin: 0;
}
.App-link {
  color: #61dafb;
}
body{
  overflow: auto !important;
}
.am-list-view-scrollview{
  /* height: 78vh !important; */
  margin: 0 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


