#root{
    width: 100%;
    min-height: 100%;
    height: 100% !important;
    /* overflow: hidden; */
}
.content{
  display: flex;
  align-items: center;
}
.extractBtn{
  width: 100%;
  background:#CC351E !important;
  font-size:15px;
  height:40px;
  border: none;
  border-radius: 5px;
  color: #fff !important;
}  
.ant-btn-dangerous.ant-btn-primary[disabled]{
  opacity: 0.6;
}